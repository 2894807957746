<template>
    <v-row class="pa-0 my-3" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="11">
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        {{$moment(aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYY년 MM월')}}&nbsp;청구&nbsp;명세서
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="p1-2" cols="12">
                    <div class="d-inline-block rounded decoBox py-4 pl-4 pr-6">
                        <div class="d-flex align-center mb-2">
                            <v-icon>mdi-menu-right</v-icon>
                            <span class="fontDotEight">총 납부하실 금액</span>
                        </div>
                        <v-divider class="lineDash ml-2"></v-divider>
                        <div class="d-flex justify-end align-end mt-2 mb-3">
                            <span class="fontOneToEight font-weight-medium red001--text">
                                {{ Number(copayInfo?.unpdAmt)?.toLocaleString('ko-KR') }}
                            </span>
                            <span class="fontOneToTwo font-weight-medium red001--text mx-1 pb-1">원</span>
                        </div>
                        <v-divider class="lineDash ml-2"></v-divider>
                        <div class="d-flex align-center my-2">
                            <v-icon>mdi-menu-right</v-icon>
                            <span class="fontDotEight">납부기한:</span>
                            <span class="fontDotEight ml-1">
                                {{ $moment(copayInfo?.pymDuDt, 'YYYYMMDD', true).format('YYYY년 MM월 DD일') }}
                            </span>
                        </div>
                    </div>
                    <div class="pl-3 pr-5 mt-4">
                        <div class="d-flex align-center">
                            <v-icon>mdi-menu-right</v-icon>
                            <span class="fontDotSeven">입금계좌:</span>
                            <span class="fontDotSeven ml-1">(&nbsp;{{ copayInfo?.dpsBnkNm1 }}&nbsp;)</span>
                            <span class="fontDotSeven ml-1">{{ copayInfo?.dpsBnkAcct1 }}</span>
                        </div>
                        <div class="d-flex align-center">
                            <v-icon>mdi-menu-right</v-icon>
                            <span class="fontDotSeven">예금주:</span>
                            <span class="fontDotSeven ml-1">{{ copayInfo?.dpsBnkHld1 }}</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0 mt-6" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        청구&nbsp;기관&nbsp;정보
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="4">
                    <span class="fontDotEight font-weight-medium">기관명</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.fcltyClnm }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="4">
                    <span class="fontDotEight font-weight-medium">주소</span>
                </v-col>
                <v-col class="txtAlgnEnd tableBL txtLnHgt-3 pa-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.addr }}</span>
                    <span class="fontDotSeven">{{ fcltyInfo.addrDtl }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="4">
                    <span class="fontDotEight font-weight-medium">전화번호</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.fcltyTel1 }}</span>
                    <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                    <span class="fontDotSeven">{{ fcltyInfo.fcltyTel2 }}</span>
                    <v-icon class="pt-1" size="12">mdi-minus</v-icon>
                    <span class="fontDotSeven">{{ fcltyInfo.fcltyTel3 }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="4">
                    <span class="fontDotEight font-weight-medium">이메일</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.emailId }}</span>
                    <v-icon class="pt-1 black--text" size="14">mdi-at</v-icon>
                    <span class="fontDotSeven">{{ fcltyInfo.emailDmn }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="4">
                    <span class="fontDotEight font-weight-medium">대표자</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.ceo }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="4">
                    <span class="fontDotEight font-weight-medium">사업자번호</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL p1-2" cols="8">
                    <span class="fontDotSeven">{{ fcltyInfo.bsnsRgst }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 mt-6" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon color="blue102">mdi-chevron-down</v-icon>
                    <span class="ml-2 font-weight-bold fontOneToOne blue102--text">
                        청구&nbsp;상세&nbsp;내역
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="5">
                    <span class="fontDotEight font-weight-medium">청구일자</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ $moment(copayClmInfo.clmDt, 'YYYYMMDD', true).endOf('month').format('YYYY년 MM월 DD일') }}
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="5">
                    <span class="fontDotEight font-weight-medium">수급자</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ copayClmInfo.name }}
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" cols="5">
                    <span class="fontDotEight font-weight-medium">급여제공 기간</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <!-- 2024.12.02 상무님 수정사항 변경 -->
                    <span class="fontDotSeven">
                        {{ $moment(copayClmInfo.ofrStart, 'YYYYMMDD', true).format('MM월 DD일') }}
                    </span>
                    <span class="mx-1 pb-1">
                        <v-icon size="14">mdi-tilde</v-icon>
                    </span>
                    <span class="fontDotSeven">
                        {{ $moment(copayClmInfo.ofrEnd, 'YYYYMMDD', true).format('MM월 DD일') }}
                    </span>

                    <!-- 2024.12.02 이전 코드 -->
                    <!-- <span class="fontDotSeven">
                        {{ $moment(copayClmInfo.clmDt, 'YYYYMMDD', true).format('MM월 DD일') }}
                    </span>
                    <span class="mx-1 pb-1">
                        <v-icon size="14">mdi-tilde</v-icon>
                    </span>
                    <span class="fontDotSeven">
                        {{ $moment(copayClmInfo.clmDt, 'YYYYMMDD', true).endOf('month').format('MM월 DD일') }}
                    </span> -->
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">본인부담금</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-a-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotNine font-weight-medium">
                        {{ Number(copayClmInfo.copay)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight font-weight-medium ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">공단부담금</span>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.nhisPay)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtLnHgt-3 py-2" cols="5">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-medium">급여 계</span>
                    </div>
                    <div class="txtAlgnMid">
                        <span class="fontDotSeven font-weight-medium">(본인+공단 부담금)</span>
                    </div>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ (Number(copayClmInfo.nhisPay) + Number(copayClmInfo.copay))?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>

            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">기타비용</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-1-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <v-btn @click="onExpsMdl()" small icon>
                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.msclnFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">이전미납금</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-2-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.unpdAmt)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">식사재료비</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-3-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.mealCost)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">상급침실비</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-4-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.upBedFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">이,미용비</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-5-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.btyFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">경관유동식</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-6-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.lqdFood)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">간식비</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-7-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.snackFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">진료약제비</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-8-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex align-center tableBL pa-2" cols="7">
                    <v-btn @click="onMdl('2')" small icon>
                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.clncPhrmcFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <div class="txtAlgnMid txtLnHgt-4 mr-1">
                        <div class="fontDotEight font-weight-medium">계약의사</div>
                        <div class="fontDotEight font-weight-medium">진찰비</div>
                    </div>
                    <v-icon size="18" style="padding-top: 1px;">mdi-numeric-9-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <v-btn @click="onMdl('1')" small icon>
                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.cntrcDctrFee)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">등급외</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-10-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.otrAmt)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">비급여 계</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-b-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotNine font-weight-medium">
                        {{
                            ( Number(copayClmInfo.msclnFee) + Number(copayClmInfo.unpdAmt) + Number(copayClmInfo.mealCost)
                            + Number(copayClmInfo.upBedFee) + Number(copayClmInfo.btyFee) + Number(copayClmInfo.lqdFood)
                            + Number(copayClmInfo.snackFee) + Number(copayClmInfo.clncPhrmcFee) + Number(copayClmInfo.cntrcDctrFee)
                            + Number(copayClmInfo.otrAmt) )?.toLocaleString('ko-KR')
                        }}
                    </span>
                    <span class="fontDotEight font-weight-medium ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>

            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 pa-2" cols="5">
                    <div class="d-flex justify-center align-center">
                        <div class="txtAlgnMid txtLnHgt-4 mr-1">
                            <div class="fontDotEight font-weight-medium">당월 본인</div>
                            <div class="fontDotEight font-weight-medium">부담금 합계</div>
                        </div>
                        <v-icon size="18" style="padding-top: 1px;">mdi-alpha-c-circle-outline</v-icon>
                    </div>
                    <div class="d-flex justify-center align-center mt-1">
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-a-circle-outline</v-icon>
                        <v-icon size="14" style="padding-top: 1px; margin-left: 1px;">mdi-plus</v-icon>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-b-circle-outline</v-icon>
                        <v-icon size="14" style="padding-top: 1px; margin-left: 1px;">mdi-minus</v-icon>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-numeric-2-circle-outline</v-icon>
                    </div>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotNine font-weight-medium">
                        {{
                            ( Number(copayClmInfo.copay) + Number(copayClmInfo.msclnFee) + Number(copayClmInfo.mealCost)
                            + Number(copayClmInfo.upBedFee) + Number(copayClmInfo.btyFee) + Number(copayClmInfo.lqdFood)
                            + Number(copayClmInfo.snackFee) + Number(copayClmInfo.clncPhrmcFee) + Number(copayClmInfo.cntrcDctrFee)
                            + Number(copayClmInfo.otrAmt) )?.toLocaleString('ko-KR')
                        }}
                    </span>
                    <span class="fontDotEight font-weight-medium ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>

            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 pa-2" cols="5">
                    <span class="fontDotEight font-weight-medium">선납적용액</span>
                    <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-d-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontDotEight">
                        {{ Number(copayClmInfo.prpymApl)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 pa-2" cols="5">
                    <div class="txtAlgnMid">
                        <span class="fontDotEight font-weight-medium">부담금 총합</span>
                    </div>
                    <div class="txtAlgnMid">
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-a-circle-outline</v-icon>
                        <v-icon size="14" style="padding-top: 1px; margin-left: 1px;">mdi-plus</v-icon>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-b-circle-outline</v-icon>
                        <v-icon size="14" style="padding-top: 1px; margin-left: 1px;">mdi-minus</v-icon>
                        <v-icon size="18" style="padding-top: 1px; margin-left: 1px;">mdi-alpha-d-circle-outline</v-icon>
                    </div>
                </v-col>
                <v-col class="d-flex justify-end align-center tableBL pa-2" cols="7">
                    <span class="fontOneToOne font-weight-medium red--text">
                        {{ Number(copayClmInfo.clmTot)?.toLocaleString('ko-KR') }}
                    </span>
                    <span class="fontDotEight font-weight-medium red--text ml-1">원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/assets/js/baseAxios';

export default {
    name: 'PayExpStt',

    components: {
      
    },

    props: {
        aplyYyMm: { type: String, default: '' },
        fcltyNum: { type: String, default: '' },
        bnMmbrPk: { type: Number, default: 0 },
        bnfcrPk: { type: Number, default: 0 },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.aplyYyMm != '' && this.bnMmbrPk == 0)
                this.getCopayClmHisInfo(this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM'))
            else if(this.bnMmbrPk != 0)
                this.getOutCopayClmHisInfo(
                    this.fcltyNum,
                    this.bnMmbrPk,
                    this.bnfcrPk,
                    this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM')
                )
        })
    },

    computed: {
       
    },

    watch:{
        'bnMmbrPk': function (){
            if(this.bnMmbrPk != 0)
                this.getOutCopayClmHisInfo(
                    this.fcltyNum,
                    this.bnMmbrPk,
                    this.bnfcrPk,
                    this.$moment(this.aplyYyMm, 'YYYYMM', true).subtract(1, 'months').format('YYYYMM')
                )
        },
    },
        
    methods: {
        //비용 명세서 조회 TOKEN
        getCopayClmHisInfo: function (yymm){
            http.post('fmly/selCopayClmHisInfo', { inqYYMM: yymm,})
                .then((response) => ( this.copayClmHisRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selCopayClmHisInfo : ' + error))
        },
        //비용 명세서 조회 Params
        getOutCopayClmHisInfo: function (code, mmbr, bnmm, yymm){
            http.post('fmly/selCopayClmHisInfo', { fcltyNum: code, bnMmbrPk: mmbr, bnfcrPk: bnmm, inqYYMM: yymm })
                .then((response) => ( this.copayClmHisRst(response.data) ))
                .catch((error) => console.log('axios error /fmly/selCopayClmHisInfo : ' + error))
        },
        copayClmHisRst: function (res){
            if(res.statusCode == '200'){
                if(res.fcltyInfo != null)
                    this.fcltyInfo = res.fcltyInfo

                this.copayInfo = res.copayInfo
                
                if(res.result != null)
                    this.copayClmInfo = res.result
            }
        },
        onMdl: function (mode){
            this.$store.commit('setPyrlSrvcMdl', true)
            this.$store.commit('setPyrlSrvcCtgMdl', '1')
            // clncClcd ( 1. 계약의사, 2. 외래진료 )
            this.$store.commit('setPyrlSrvcCtgObj', { clncClcd: mode })
            //console.log(this.$store.state.pyrlSrvcCtgMdl.yNo)
        },
        onExpsMdl: function (){
            this.$store.commit('setPyrlSrvcMdl', true)
            this.$store.commit('setPyrlSrvcCtgMdl', '2')
            this.$store.commit('setPyrlSrvcCtgObj', {})
            //console.log(this.$store.state.pyrlSrvcCtgMdl.yNo)
        },
    },

    data: () => ({
        fcltyInfo: {},
        copayInfo: {},
        copayClmInfo: {},
    }),
};
</script>